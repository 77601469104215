<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
  <ng-container *ngIf="alarmCount > 0">
    <span
      class="absolute top-0 right-0 left-0 flex items-center justify-center h-3"
    >
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-[3px] bg-primary text-indigo-50 text-xs font-medium"
      >
        {{ alarmCount > 99 ? '99+' : alarmCount }}
      </span>
    </span>
  </ng-container>
  <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-lg overflow-hidden shadow-lg"
  >
    <!-- Header -->
    <div
      class="flex shrink-0 items-center justify-between pt-4 px-4 bg-neutral-600 text-on-primary"
    >
      <div class="flex items-center justify-between w-full">
        <div class="sm:hidden -ml-1 mr-3">
          <button mat-icon-button (click)="closePanel()">
            <mat-icon
              class="icon-size-5 text-current"
              [svgIcon]="'heroicons_solid:x'"
            ></mat-icon>
          </button>
        </div>
        <div class="font-semibold">Notifications</div>
        <div class="">
          <!-- <button
            [matTooltip]="'Mark all as read'"
            [disabled]="unreadCount === 0"
            (click)="markAllAsRead()"
            class="text-sm font-semibold"
            [ngClass]="unreadCount === 0 ? 'text-neutral-200' : 'text-primary'"
          >
            Mark all as read
          </button> -->
        </div>
      </div>
    </div>

    <!-- header notification -->
    <mat-tab-group
      animationDuration="0ms"
      class="bg-neutral-600"
      (selectedTabChange)="selectedTabChange($event)"
      [disableRipple]="true"
    >
      <mat-tab class="">
        <ng-template mat-tab-label>
          General
          <span
            class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-[7px] rounded-[3px] text-indigo-50 text-xs font-medium"
            [ngClass]="
              notificationEventsSelected ? 'bg-primary' : 'bg-neutral-400 '
            "
          >
            {{ alarmCount > 99 ? '99+' : alarmCount }}
          </span>
        </ng-template>
        <ng-template matTabContent>
          <!-- Content -->
          <cdk-virtual-scroll-viewport
            autosize
            itemSize="40"
            class="h-60"
            (scrolledIndexChange)="scrolledEventIndexChange($event)"
          >
            <!-- <div *cdkVirtualFor="let notification of notificationEvents">
              <div
                *ngIf="notification.url && notification.title !== ''"
                [ngClass]="
                  !notification.read
                    ? 'bg-[#3A3E46]'
                    : 'hover:bg-black hover:bg-opacity-5'
                "
              >
                <ng-container>
                  <ng-container *ngIf="notification.url">
                    <div
                      class="flex flex-auto py-2 px-4 cursor-pointer"
                      (click)="
                        onClickNotification(
                          'event',
                          notification.id,
                          notification.url
                        )
                      "
                    >
                      <ng-container
                        *ngTemplateOutlet="notificationContent"
                      ></ng-container>
                    </div>
                  </ng-container>
                </ng-container>

                <div
                  class="border-neutral-400 border-t mx-4"
                  *ngIf="notification.url && notification.title !== ''"
                ></div>
              </div>

              <ng-template #notificationContent>
                <div class="flex flex-col flex-auto" *ngIf="notification.title">
                  <ng-container *ngIf="notification.title">
                    <div
                      class="line-clamp-2 font-semibold text-neutral-50"
                      [innerHTML]="notification.title"
                    ></div>
                  </ng-container>
                  <div class="mt-1 text-sm leading-none text-neutral-100">
                    {{ notification.createdAt | dateToTimestampAgoPipe }}
                  </div>
                </div>
              </ng-template>
            </div> -->

            <!-- <div *cdkVirtualFor="let notification of notificationAlarmEvents">
                <div class="flex flex-col flex-auto">
                  <ng-container *ngIf="notification.type">
                    <div
                      class="line-clamp-2 font-semibold text-neutral-50"
                      [innerHTML]="notification.type"
                    ></div>
                  </ng-container>
                  <div class="mt-1 text-sm leading-none text-neutral-100">
                    {{ notification.alarmTime | dateToTimestampAgoPipe }}
                  </div>
                </div>
            </div> -->

            <div *cdkVirtualFor="let notification of notificationAlarmEvents; let i = index">
              <div
                class="hover:bg-black hover:bg-opacity-5"
              >
                <div
                  class="flex flex-auto py-2 px-4 cursor-pointer"
                  (click)="
                    onClickAlarmNotification(
                      i,
                      notification.id
                    )
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="notificationContent"
                  ></ng-container>
                </div>

                <div
                  class="border-neutral-400 border-t mx-4"
                ></div>
              </div>

              <ng-template #notificationContent>
                <div class="flex flex-col flex-auto" *ngIf="notification.type">
                  <ng-container *ngIf="notification.type">
                    <div
                      class="line-clamp-2 font-semibold text-neutral-50"
                      [innerHTML]="getAlarmTypeName(notification.type)"
                    ></div>
                  </ng-container>
                  <div class="mt-1 text-sm leading-none text-neutral-100">
                    {{ notification.alarmTime | dateToTimestampAgoPipe }}
                  </div>
                </div>
              </ng-template>
            </div>

            <!-- No notifications Event-->
            <!-- <ng-container *ngIf="!notificationEvents"> -->
            <ng-container *ngIf="!notificationAlarmEvents">
              <div
                class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
              >
                <div
                  class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-neutral-100"
                >
                  <mat-icon
                    class="text-netral-200"
                    [svgIcon]="'heroicons_outline:bell'"
                  ></mat-icon>
                </div>
                <div
                  class="mt-5 text-2xl font-semibold tracking-tight text-neutral-50"
                >
                  No notifications
                </div>
                <div
                  class="w-full max-w-60 mt-1 text-md text-center text-neutral-50"
                >
                  When you have notifications, they will appear here.
                </div>
              </div>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab class="ml-4">
        <ng-template mat-tab-label>
          Missed Calls
          <span
            class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-[7px] rounded-[3px] text-indigo-50 text-xs font-medium"
            [ngClass]="
              notificationTeleconferencesSelected
                ? 'bg-primary'
                : 'bg-neutral-400 '
            "
          >
            {{
              unreadTeleconferenceCount > 99 ? '99+' : unreadTeleconferenceCount
            }}
          </span>
        </ng-template>
        <ng-template matTabContent>
          <cdk-virtual-scroll-viewport
            autosize
            itemSize="50"
            class="h-60"
            (scrolledIndexChange)="scrolledTeleconferenceIndexChange($event)"
          >
            <ng-container
              *cdkVirtualFor="let notification of notificationTeleconferences"
            >
              <div
                *ngIf="notification.title !== ''"
                [ngClass]="
                  !notification.read
                    ? 'bg-[#3A3E46]'
                    : 'hover:bg-black hover:bg-opacity-5'
                "
              >
                <ng-container>
                  <ng-container>
                    <div
                      class="flex flex-auto py-2 px-4 cursor-pointer"
                      (click)="
                        onClickNotification('teleconference', notification.id)
                      "
                    >
                      <ng-container
                        *ngTemplateOutlet="notificationContent"
                      ></ng-container>
                    </div>
                  </ng-container>
                </ng-container>

                <div
                  class="border-neutral-400 border-t mx-4"
                  *ngIf="notification.title !== ''"
                ></div>
              </div>

              <ng-template #notificationContent>
                <div class="flex flex-col flex-auto" *ngIf="notification.title">
                  <ng-container *ngIf="notification.title">
                    <div
                      class="line-clamp-2 font-semibold text-neutral-50"
                      [innerHTML]="notification.title"
                    ></div>
                  </ng-container>
                  <div class="mt-1 text-sm leading-none text-neutral-100">
                    {{ notification.createdAt | dateToTimestampAgoPipe }}
                  </div>
                </div>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="!notificationTeleconferences">
              <div
                class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
              >
                <div
                  class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-neutral-100"
                >
                  <mat-icon
                    class="text-netral-200"
                    [svgIcon]="'heroicons_outline:bell'"
                  ></mat-icon>
                </div>
                <div
                  class="mt-5 text-2xl font-semibold tracking-tight text-neutral-50"
                >
                  No notifications
                </div>
                <div
                  class="w-full max-w-60 mt-1 text-md text-center text-neutral-50"
                >
                  When you have notifications, they will appear here.
                </div>
              </div>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </mat-tab> -->
    </mat-tab-group>
    <!-- divider -->
    <div class="border-neutral border-t pt-[6px] pb-3 bg-neutral-600"></div>
  </div>
</ng-template>
