import { MqttEnv } from './environment.interface';

const hostnameRegex =
  /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/;
const ipAddress = hostnameRegex.test(window.location.hostname);
const HOSTNAME = ipAddress
  ? window.location.hostname
  : `video.${window.location.hostname}`;
const videoHost = 'video.dev.robotmanager.io';
const SIGNALING_HOSTNAME = ipAddress
  ? `${window.location.hostname}:3478`
  : `l.google.com:19305`;

export const environment = {
  production: true,
  apiUrl: '/api',
  coreApiUrl: '/core',
  coreUrl: 'https://demo.robotmanager.io',
  peerJsOptions: {
    host: videoHost,
    port: 9000,
    path: '/peerjs',
    secure: true,
    debug: 0,
    config: {
      iceServers: [
        {
          urls: [`stun:stun.${SIGNALING_HOSTNAME}`],
        },
      ],
    },
  },
};

export const mqttEnv: MqttEnv = {
  mqttProtocol: 'wss',
  mqttHost: 'demo.robotmanager.io',
  mqttPort: 8443,
};
