import { Inject, Injectable } from '@angular/core';
import { ApiBase, ListQuery, ResponseList, ResponseOne } from 'rm-api-services/dist/api-services';
import { map, Observable, of, ReplaySubject, Subject, tap } from 'rxjs';
import { Alarm } from './api.types';
import { HttpClient } from '@angular/common/http';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';

@Injectable({
  providedIn: 'root'
})
export class ApiAlarmService extends ApiBase<Alarm> {

  severityBgColorMap = {
    'Critical': '#FF5630',
    'Major': '#FFAB00',
    'Minior': '#6554C0'
  }

  statusBgColorMap = {
    'Acknowledged': '#36B37E',
    'Pending': '#04B4CD',
    'Closed': '#A5ADBA',
    'Ignored': '#FFAB00'
  }

  public alarmTypeLists: SelectOption[] = [ 
    { display: 'Water Spillage', value: 'PuddleDetection' },
    { display: 'Garbage', value: 'GarbageDetection' },
    { display: 'Illegally Parked Vehicle', value: 'VehicleDetection' },
    { display: 'Cracked Wall Detection', value: 'CrackedWallDetection' },
    { display: 'Graffiti Detection', value: 'GraffitiDetection' },
    { display: 'Exposed Wiring Detection', value: 'ExposedWiringDetection' },
    { display: 'Faulty Lighting Detection', value: 'FaultyLightingDetection' },
  ];
  
  public severityLists: SelectOption[] = [
    { display: 'Critical', value: 'Critical' },
    { display: 'Major', value: 'Major' },
    { display: 'Minor', value: 'Minior' },
  ];

  public statusLists: SelectOption[] = [
    { display: 'Acknowledged', value: 'Acknowledged' },
    { display: 'Pending', value: 'Pending' },
    { display: 'Closed', value: 'Closed' },
    { display: 'Ignored', value: 'Ignored' },
  ];

  getAlarmTypeName(alarmType) {
    return this.alarmTypeLists.find((x) => x.value === alarmType)?.display;
  }
  
  tempAlarms: ResponseList<Alarm>;
  private _alarms: ReplaySubject<ResponseList<Alarm>> = new ReplaySubject<ResponseList<Alarm>>(1);

  constructor(
    http: HttpClient,
    @Inject('environment') environment: any
  ) { 
    super(http, environment);
    this.resourceBaseUrl = 'alarm';
    this.base = environment.apiUrl;
  }

  public updateAlarm =(query: Partial<Alarm>): Observable<ResponseOne<Alarm>>  => 
   this.http.post<ResponseOne<Alarm>>(
      `${this.base}/${this.resourceBaseUrl}/update/${query.id}`,
      query
    );
  

}
