import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { ApiLayoutMap } from 'app/services/layout.service';
import L, { LeafletEvent, LeafletMouseEvent } from 'leaflet';
import { RobotTeleopsService } from 'app/services/robot-teleops.service';
import { get } from 'lodash';
import {
  ApiLayoutMarker,
  ApiRobot,
  ApiTrafficGraph,
  createUuid,
  Event,
  Layout,
  LayoutMarker,
  TrafficGraph,
  MqttSettings,
  ResponseOne,
  Robot,
  Vertex,
} from 'rm-api-services/dist/api-services';
import {
  forkJoin,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { SelectOption } from '../form-select/form-select.component';
import _ from 'lodash';
import {
  FullPathPlanner,
  FullPathPlannerPayload,
  MapRobotMarker,
  PointRegion,
  Region,
  RobotSocketData,
} from 'app/services/api.types';
import { ApiPlannedPath } from 'app/services/planned-path.service';
import { TeleOperationService } from 'app/modules/teleoperations/teleoperations.service';
import { UtilityService } from 'app/services/utility.service';
import { MiniLayoutMapService } from './mini-layout-map.service';
import { ApiRegion } from 'app/services/region.service';
import { NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { SnackBarService } from 'app/services/snack-bar.service';

interface PointCoordinate {
  x: number;
  y: number;
  z: number;
}

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

@Component({
  selector: 'shared-mini-layout-map',
  templateUrl: './mini-layout-map.component.html',
  styleUrls: ['./mini-layout-map.component.scss'],
})
export class MiniLayoutMapComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('miniLayoutMapContainer', { static: true }) leafletRef: ElementRef;

  @Input() isShowAlert: boolean = false;
  @Input() isReadOnlyMap: boolean = false;
  @Input() isTeleopMap: boolean = false;
  @Input() isFadingMap: boolean = false;
  @Input() eventLocation: PointCoordinate;
  @Input() titleDetectionDetail: string;
  @Input() classContainerHeight: string = 'h-80';
  @Input() canClickOnMap: boolean = false;
  @Input() selectedZoneIds: string[] = [];
  @Input() isShowTrafficLanes: boolean = false;
  @Input() isUseRealRobotPosition: boolean = false;
  @Input() isShowRobotSonar: boolean = false;
  @Output() changeMarker = new EventEmitter<{
    event: SelectOption;
    action: 'map' | 'marker' | 'zone';
  }>();
  @Input() alarmMarker: {
    x: number, y: number, name: string
  }

  public layoutName: string;
  public siteName: string;

  private layoutId: string;
  private robotId: string;
  private markers: LayoutMarker[];
  private robot: MapRobotMarker;
  private robots: MapRobotMarker[] = [];
  private zones: ZoneArea[] = [];
  private trafficGraph: TrafficGraph;
  private isBlinking = false;
  private isRobotMarkerRendered = false;
  private markerLabelTemp: Element;
  private markerIconTemp: Element;
  private markerListTemp: any[];
  private dispatchRobotLocationFromMap: Vertex;
  private onLineRobotIdList: string[] = [];
  private checkOfflineTimer;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  activeM: boolean = false;

  constructor(
    private leafletService: MiniLayoutMapService,
    private apiLayoutMap: ApiLayoutMap,
    private apiLayoutMarker: ApiLayoutMarker,
    private dashboardService: DashboardService,
    private apiRobot: ApiRobot,
    private apiTrafficGraph: ApiTrafficGraph,
    private apiPlannedPath: ApiPlannedPath,
    private mqttSettings: MqttSettings,
    private robotTeleServices: RobotTeleopsService,
    private teleopsSvc: TeleOperationService,
    private utilService: UtilityService,
    private apiRegion: ApiRegion,
    private snackbar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.apiLayoutMap.selectedLayoutId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((layoutId) => {
        this.layoutId = layoutId;
        if(this.layoutId) {
          this.removeMap();
          const teleopLayoutId = this.isTeleopMap ? layoutId : null;
          this.leafletService.initMap(
            this.leafletRef,
            {
              crs: L.CRS.Simple,
              minZoom: this.isTeleopMap ? -5 : -2,
              maxZoom: 5,
              zoomSnap: 0.1,
              attributionControl: false,
              zoomControl: false,
              doubleClickZoom: false,
              scrollWheelZoom: !this.isTeleopMap,
              dragging: !this.isTeleopMap,
              click: (e) => this.onMapClick(e),
            },
            this.isTeleopMap,
            teleopLayoutId
          );
          this.loadImageOverlay();
          if (this.alarmMarker) {
            this.addAlarmMarker(this.alarmMarker);
          }
        }
      });

    this.dashboardService.selectedRobotId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robotId) => {
        this.robotId = robotId;
        if (this.robotId) {
          this.getRobotDetail();
        } else {
          this.robot = undefined;
        }
      });

    this.dashboardService.selectedRobots$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robots) => {
        if (robots.length > 0) {
          this.getMultipleRobotDetail(robots);
          this.loadZoneAreasFromServer();
        } else {
          this.robots = [];
        }
      });

    // If patrol area route is updating when create patrol area job
    this.apiLayoutMap.routingList$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((routingList) => {
        if (!routingList) return;
        if (!this.robot && this.robots.length === 0) return;
        // update marker color based on selected route
        this.updateMarkerOnSelected(JSON.stringify(routingList), 'patrol');

        if (this.leafletService.isMapInitiate()) {
          // If there is a route selected, render the line
          // between each node. Otherwise remove the line
          // if there is not any route selected
          if (routingList.length > 0) {
            this.robots.map((robot) => {
              let plannedPath = [];
              let count = 0; // Used to check if all the route already has the planned path list
              // Iterate over the list to show the planned path for each selected route
              routingList.map(async (route, i) => {
                console.log('ROUTE ', route);

                // If has only one route, it will get the planned path from
                // robot location to the selected node.
                // Otherwise it will get the planned path from different start node
                // and destination node depend on node selected in patrol area dropdown.
                if (routingList.length === 1) {
                  const prevRoute = JSON.stringify(robot.point);
                  const planned = await this.getSelectedPlannedPath(
                    route,
                    prevRoute
                  );
                  plannedPath = [...planned];

                  console.log('PLANNED PATH ', plannedPath);

                  this.mapDataToCoords(plannedPath, robot.id);
                } else {
                  // It will use the robot location for start node,
                  // if it is the first route in the list. Otherwaise it will use prev node
                  const prevRoute =
                    i === 0 ? JSON.stringify(robot.point) : routingList[i - 1];
                  const planned = await this.getSelectedPlannedPath(
                    route,
                    prevRoute
                  );
                  plannedPath[i] = [...planned];
                  count++;
                  // Check if all planned path has assigned to each route in list
                  if (count === routingList.length) {
                    // parse Multi-dimensional array into one-dimensional array
                    const paths = ([] as FullPathPlanner[]).concat(
                      ...plannedPath
                    );
                    this.mapDataToCoords(paths, robot.id);
                  }
                }
              });
            });
          } else {
            this.robots.map((robot) => {
              this.leafletService.removePlannedPath(robot.id);
            });
          }
        }
      });

    // If selected node is updated when create goto job
    this.apiLayoutMap.selectedDestinationMarker$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((selectedMarker) => {
        // check if there's selected marker
        // if there is one, remove active class from the element
        if (this.markerLabelTemp || this.markerIconTemp) {
          this.markerLabelTemp.classList.remove('active');
          this.markerIconTemp.classList.remove('active');
        }

        if (!selectedMarker) return;

        // remove temporary marker if select marker from existing one
        try {
          const parsedData = JSON.parse(selectedMarker);
          if (parsedData.name !== '') {
            this.leafletService.removeMarker(
              'new-marker-from-click-map',
              'dispatch-marker'
            );
          }
        } catch (err) {
          console.log(err);
        }

        // update marker color on clicked
        this.updateMarkerOnSelected(selectedMarker, 'goto');

        if (
          this.leafletService.isMapInitiate() &&
          selectedMarker &&
          this.robotId
        ) {
          this.getSelectedPlannedPath(selectedMarker).then((paths) => {
            this.mapDataToCoords(paths, this.robotId);
          });
        }
      });

    this.teleopsSvc.highlightSpace.subscribe((flag) => {
      this.activeM = flag;
    });
  }

  private addAlarmMarker(markerData): void {
    this.leafletService.removeMarker(
      'new-marker-from-click-map',
      'dispatch-marker'
    );

    this.apiLayoutMarker.listLayoutMarkers(this.layoutId).subscribe((resp) => {
      this.markers = resp.result.filter(
        (marker) => marker.metadata?.type === 'marker'
      );
      // const closeMarker = this.findClosestMarker({x: markerData.x, y: markerData.y}, this.markers)
      const newMarker = {
        id: createUuid(),
        layoutId: this.layoutId,
        name: markerData.name,
        layout: {
          x: markerData.x,
          y: markerData.y,
          z: 0,
        },
      } as unknown as Event;
      // render it on leaflet
      this.leafletService.renderLayoutMarker(newMarker, 'alarm-marker', {
        opacity: 1,
        draggable: false,
      });
    });
  }

  findClosestMarker(reference: { x: number; y: number }, points:LayoutMarker[]): LayoutMarker | null {
    let closestMarker: LayoutMarker | null = null;
    let closestDistance = Infinity;
    for (const markerInfo of points) {
      if (this.utilService.canShowMarkerIconOnLayout(markerInfo.name) && markerInfo.position) {
        const distance = this.calculateDistance(reference, { x: markerInfo.position.x, y: markerInfo.position.y });
        if (distance < closestDistance) {
            closestDistance = distance;
            closestMarker = markerInfo;
        }
      }
    }
    return closestMarker;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isFadingMap) {
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if (this.robot || this.robots) {
      const robots = this.robot ? [this.robot] : this.robots;
      this.leafletService.stopRobotMovement(robots);
    }
    if (this.checkOfflineTimer) {
      clearInterval(this.checkOfflineTimer);
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Use to set map zoom level when user use zoom tool button (+ / -)
   *
   * @param zoomType Type of the zoom (zoom-in / zoom-out)
   */
  public setZoom(zoomType: string): void {
    switch (zoomType) {
      case 'zoom-in':
        this.leafletService.zoomIn(0.5);
        break;
      case 'zoom-out':
        this.leafletService.zoomOut(0.5);
        break;
    }
  }

  /**
   * Funtion to load layout map for selected layoutId.
   * Then load the marker list and robot list from Api. Also init the MQTT message.
   */
  private loadImageOverlay(): void {
    this.apiLayoutMap
      .getLayoutImageByID(this.layoutId)
      .subscribe((response) => {
        this.leafletService.loadMapImage(
          response.url,
          response.width,
          response.height
        );

        if (!this.isTeleopMap && !this.eventLocation) {
          this.getTrafficGraphData();
        }

        if (this.robotId) {
          this.getRobotDetail();
        }

        if (this.eventLocation) {
          this.loadMarkersFromServer();
          this.renderEventOnMap();
        }

        if (this.isTeleopMap) {
          this.getLocationDetail();
          this.robotTeleServices
            .getLightState(this.robotId)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isBlinking) => {
              this.updateBlinkingMarker(isBlinking);
            });
        }

        // init MQTT subscriber
        this.initMqtt();
        if (this.isUseRealRobotPosition) {
          this.checkRobotOfflineTimer();
        }
      });
  }

  /**
   * Helper function to remove the map before render the new map
   */
  private removeMap(): void {
    this.leafletRef.nativeElement.innerHTML = '';
    this.leafletService.removeMap();
  }

  /**
   * Helper function to load layout marker list from API based on selected layoutId, then render it to the map
   */
  private loadMarkersFromServer(): void {
    this.apiLayoutMarker.listLayoutMarkers(this.layoutId).subscribe((resp) => {
      this.markers = resp.result.filter(
        (marker) => marker.metadata.type === 'marker' || !marker.metadata.type
      );
      this.leafletService.renderLayoutMarkers(this.markers, 'marker', {
        opacity: 1,
        draggable: false,
      });
    });
  }

  /**
   * Helper function to load layout marker list from API based on selected layoutId, then render it to the map
   */
  private getRobotDetail(): void {
    this.apiRobot.getById(this.robotId).subscribe((resp) => {
      const robotType = this.isTeleopMap ? 'teleop-robot' : 'robot';
      this.robot = {
        ...resp.result,
        blinking: this.isBlinking,
      };

      if (!this.isTeleopMap) {
        this.leafletService.renderLayoutMarker(
          this.robot,
          robotType,
          {
            opacity: 1,
            draggable: false,
          },
          this.isShowRobotSonar
        );
        this.isRobotMarkerRendered = true;
        this.robots = [this.robot];
      }

      // if (this.isTeleopMap && this.robot) {
      //   const coordinates = this.leafletService.xYtoLatlng('minimap', [
      //     this.robot.point.x,
      //     this.robot.point.y,
      //   ]);
      //   this.leafletService.flyTo('minimap', coordinates);
      // }
    });
  }

  /**
   * When marker is clicked, select that marker,
   * then update the selectedMarkerId$ observable,
   * so the dropdown in the goto job and patrol job is updated
   *
   * @param e
   */
  private onNodeClick(e: LeafletEvent): void {
    if (this.isReadOnlyMap) {
      return;
    }

    const selectedNode = get(e, 'target.options.markerId', '') as string;
    const node = this.trafficGraph.vertices[selectedNode];

    if (!node) {
      return;
    }

    // remove temporary marker if select marker from existing one
    this.leafletService.removeMarker(
      'new-marker-from-click-map',
      'dispatch-marker'
    );

    const selectionOption: SelectOption = {
      display: node.name,
      value: JSON.stringify({
        layoutId: this.layoutId,
        markerId: selectedNode,
        name: node.name,
        x: node.x,
        y: node.y,
        z: 0,
      }),
    };
    this.changeMarker.emit({ event: selectionOption, action: 'marker' });
  }

  /**
   * Helper function to render event marker in map
   * for detection location
   */
  private renderEventOnMap(): void {
    const newMarker = {
      id: createUuid(),
      layoutId: this.layoutId,
      name: this.titleDetectionDetail,
      layout: {
        x: this.eventLocation.x,
        y: this.eventLocation.y,
        z: 0,
      },
    } as unknown as Event;

    // render it on leaflet
    this.leafletService.renderLayoutMarker(newMarker, 'event', {
      opacity: 1,
      draggable: false,
    });
  }

  /**
   * Helper function to load traffic graph data from API, then render it to the map based on selected layoutId
   */
  private getTrafficGraphData(): void {
    this.apiTrafficGraph.getGraphByLayout(this.layoutId).subscribe((res) => {
      if (res.code === 200 && res.result) {
        // render the robot marker in map
        this.trafficGraph = res.result;
        // render each traffic graph nodes
        if(!this.alarmMarker) {
        _.forEach(this.trafficGraph.vertices, (value, key) => {
          if (!value.hasOwnProperty('id')) {
            value['id'] = key;
          }
          // remove lift floor points in traffic graph when render the nodes.
          if (this.utilService.canShowMarkerIconOnLayout(value.name) && !value['lift']) {
            this.leafletService.renderLayoutMarker(
              value,
              'traffic-graph-marker',
              {
                opacity: 1,
                draggable: false,
                click: (e) => this.onNodeClick(e),
              }
            );
          }
        });
        }

        if (this.isShowTrafficLanes) {
          // render each traffic graph lanes
          _.forEach(this.trafficGraph.lanes, (value, key) => {
            const startVertex = this.trafficGraph.vertices[key];

            // it give error if not convert to unknown first. Although the respon from API is array
            const lanes: string[] = value as unknown as string[];
            lanes.forEach((lane) => {
              const endVertex = this.trafficGraph.vertices[lane];
              // assign the coordinate for each traffic graph node that have paired
              const startPoint = {
                x: startVertex.x,
                y: startVertex.y,
                z: 0,
              };
              const endPoint = {
                x: endVertex.x,
                y: endVertex.y,
                z: 0,
              };
              // remove the lift floor lane between taansit point and waiting/exit point
              if(!startVertex['lift'] || !endVertex['lift']) {
                this.leafletService.renderLineMarker(startPoint, endPoint, lane, {
                  color: '#2984FF', // use tailwindcss 'info.DEFAULT' color theme
                  weight: 3,
                  opacity: 0.3,
                });
              }
            });
          });
        }
      }
    });
  }

  findClosestKey(reference: { x: number; y: number }, points: {[key: string]: Vertex;}): string | null {
    let closestKey: string | null = null;
    let closestDistance = Infinity;

    for (const key in points) {
      // if (this.utilService.canShowMarkerIconOnLayout(value.name) && !value['lift']) {
        if (points.hasOwnProperty(key)) {
            const point = points[key];
            if (this.utilService.canShowMarkerIconOnLayout(point.name) && !point['lift']) {
              const distance = this.calculateDistance(reference, { x: point.x, y: point.y });
              if (distance < closestDistance) {
                  closestDistance = distance;
                  closestKey = key;
              }
          }
        }
    }

    return closestKey;
}

calculateDistance(point1: { x: number; y: number }, point2: { x: number; y: number }): number {
    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
}

  /**
   * Helper funtion to get the planned path form API
   * based on selected, and robot position and traffic graph data
   *
   * @param destinationNode Selected node for the destination
   * @param startNode Selected node where robot come. It can be robot position or previous node in patrol area job. If not declare, it will use robot position
   */
  private getSelectedPlannedPath(
    destinationNode: string,
    startNode?: string
  ): Promise<FullPathPlanner[]> {
    return new Promise((resolve) => {
      const destination: Vertex = JSON.parse(destinationNode);
      const start: Vertex | PointCoordinate = startNode
        ? JSON.parse(startNode)
        : this.robot.point;
      const payload: FullPathPlannerPayload = {
        source: {
          coord: [start.x, start.y],
          layout: this.layoutId,
        },
        destination: {
          coord: [destination.x, destination.y],
          layout: this.layoutId,
        },
        companyId:
          localStorage.getItem('company_id') ||
          'c0000000-c000-c000-c000-c00000000001',
      };

      this.apiPlannedPath.getFullPlannedPath(payload).subscribe((response) => {
        if (response.code === 200) {
          const plannedPath = response.result;
          resolve(plannedPath);
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * Helper function to get layout and site name.
   * If the location in the site, it will get
   * the site name using the parentId in the layout data.
   * Then show the site name and the layout name
   */
  private getLocationDetail(): void {
    // get the layout detail first. if the parentId
    // is not null UUID, it will get the site detail
    // then combine the result into one obseravble and
    // return as the layout and site name
    this.apiLayoutMap
      .getById(this.layoutId, true)
      .pipe(
        switchMap((resp: ResponseOne<Layout>) => {
          let site$: Observable<Layout> = of(null);
          if (resp.result && resp.result.parentId !== NIL_UUID) {
            site$ = from(this.getSiteDetail(resp.result.parentId));
          }

          return forkJoin([of(resp.result), site$]);
        }),
        map(([layout, site]) => {
          if (site) {
            return {
              layoutName: layout.name,
              siteName: site.name,
            };
          }
          return { layoutName: layout.name };
        })
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp) => {
        if (resp.siteName) {
          this.siteName = resp.siteName;
        }
        this.layoutName = resp.layoutName;
      });
  }

  /**
   * Helper function to get detail of site
   *
   * @param siteId
   * @returns
   */
  private getSiteDetail(siteId: string): Promise<Layout | null> {
    return new Promise((resolve) => {
      this.apiLayoutMap
        .getById(siteId, true)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((resp) => {
          if (resp.code === 200 && resp.result) {
            resolve(resp.result);
          } else {
            resolve(null);
          }
        });
    });
  }

  /**
   * Helper function to mapping the coordinates for each
   * nodes traversed by the robot from robot location to the
   * selected destination node and render the path in the map
   *
   * @param data Nodes list that traversed by the robot to the selected destination node
   * @param robotId ID of robot
   */
  private mapDataToCoords(data: FullPathPlanner[], robotId: string) {
    const coords: L.LatLng[] = [];
    data.forEach((element) => {
      const latlng = this.leafletService.xYtoLatlng(element.coord);
      coords.push(latlng);
    });

    if (this.leafletService.isPlannedPathShow()) {
      this.leafletService.removePlannedPath(robotId);
    }

    // render the path in the map
    this.leafletService.renderPathPlanned(robotId, coords, {
      color: '#7168E4', // use tailwindcss 'primary.DEFAULT' color theme
      weight: 5,
      dashArray: '20, 20',
    });
  }

  private initMqtt(): void {
    // Subscribe to MQTT robot status topic
    this.mqttSettings.socketRobotData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: RobotSocketData) => {
        if (this.isTeleopMap) {
          this.updateTeleopRobotPosition(data);
        }

        if (this.isUseRealRobotPosition) {
          this.updateRobotPosition(data);
        }
      });

    // Subscribe to MQTT robot state topic
    this.mqttSettings.socketRbtStateData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: RobotSocketData) => {
        if (this.isUseRealRobotPosition) {
          this.updateRobotStateBySocket(data);
        }
      });
  }

  private updateBlinkingMarker(isBlinking: boolean): void {
    this.isBlinking = isBlinking;
    this.robot = {
      ...this.robot,
      blinking: this.isBlinking,
    };

    this.leafletService.updateBlinkingMarkerIndicator(
      this.robot.id,
      this.isBlinking
    );
  }

  private updateMarkerOnSelected(selectedMarker: string, type: string) {
    if (type === 'goto') {
      // parse string to json object
      let markerObj = JSON.parse(selectedMarker);

      // get marker label element
      let markerLabel = document.getElementById(
        `${markerObj?.markerId}_minimap_traffic-graph-marker`
      )?.children[0];

      // get marker icon element
      let markerIcon = document.getElementById(
        `${markerObj?.markerId}_minimap_traffic-graph-marker`
      )?.children[1];

      // set temporary marker icon and label for checking active/selected marker
      this.markerLabelTemp = markerLabel;
      this.markerIconTemp = markerIcon;

      // add active class to element if marker is selected
      markerLabel?.classList.add('active');
      markerIcon?.classList.add('active');
    } else {
      let markerList = JSON.parse(selectedMarker);
      let markerListTemp = [];
      let diff = [];

      // check if marker list is not empty
      if (markerList && markerList.length > 0) {
        // iterate marker list as the given data in patrol job is list of string
        for (const mi of markerList) {
          // parse marker list value to object bcs it's given string of object
          let markerObj = JSON.parse(mi);

          // add active class to element if marker is selected
          document
            .getElementById(
              `${markerObj.markerId}_minimap_traffic-graph-marker`
            )
            .children[0].classList.add('active');
          document
            .getElementById(
              `${markerObj.markerId}_minimap_traffic-graph-marker`
            )
            .children[1].classList.add('active');

          // add marker object value to marker list temp
          markerListTemp.push(markerObj);
        }
      }

      // check if global marker list temp is not empty
      if (this.markerListTemp && this.markerListTemp.length > 0) {
        // get difference between global marker list temp and marker list temp
        diff = this.markerListTemp.filter(
          (value1) =>
            !markerListTemp.some(
              (value2) => value2.markerId === value1.markerId
            )
        );

        // check if the difference is not empty
        if (diff.length > 0) {
          // iterate list of differences
          for (const d of diff) {
            // remove active class from the element in difference list
            document
              .getElementById(`${d.markerId}_minimap_traffic-graph-marker`)
              .children[0].classList.remove('active');
            document
              .getElementById(`${d.markerId}_minimap_traffic-graph-marker`)
              .children[1].classList.remove('active');
          }
        }
      }

      // set global marker list temp
      this.markerListTemp = markerListTemp;
    }
  }

  private onMapClick(e: LeafletMouseEvent): void {
    if (!this.canClickOnMap) {
      return;
    }

    const isValid = this.leafletService.isValidCoordinate(e.latlng);
    if (!isValid) {
      return;
    }
    const xy = this.leafletService.latlngToXy(e.latlng);

    // create new marker, if exist, remove old marker.
    // It is only created temporary to show clicked area on map to dispatch robot location.
    // When user use map coordinate
    this.dispatchRobotLocationFromMap = {
      capacity: 1,
      id: 'new-marker-from-click-map',
      name: 'Emergency Dispatch',
      number: '0',
      type: 'point',
      x: xy.x,
      y: xy.y,
    };

    this.leafletService.renderLayoutMarker(
      this.dispatchRobotLocationFromMap,
      'dispatch-marker',
      {
        opacity: 1,
        draggable: true,
        click: (e) => this.onNodeMapClicked(e),
        dragend: (e) => this.onNodeMapDragEnd(e),
      }
    );

    const selectionOption: SelectOption = {
      display: 'Emergency Dispatch',
      value: JSON.stringify({
        layoutId: this.layoutId,
        name: '',
        x: xy.x,
        y: xy.y,
        z: 0,
      }),
    };
    this.changeMarker.emit({ event: selectionOption, action: 'map' });
  }

  /**
   * When marker is clicked, select that marker,
   * it is used to update the selected marker,
   * so the dropdown in the dispatch robot is updated
   *
   * @param e
   */
  private onNodeMapClicked(e: LeafletEvent): void {
    if (this.isReadOnlyMap) {
      return;
    }

    const node = this.dispatchRobotLocationFromMap;

    if (!node) {
      return;
    }

    const selectionOption: SelectOption = {
      display: node.name,
      value: JSON.stringify({
        layoutId: this.layoutId,
        name: '',
        x: node.x,
        y: node.y,
        z: 0,
      }),
    };
    this.changeMarker.emit({ event: selectionOption, action: 'map' });
  }

  /**
   * random color generator
   */
  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  private loadZoneAreasFromServer(): void {
    this.apiRegion.listZoneAreas(this.layoutId).subscribe((resp) => {
      if (resp.code === 200) {
        let zoneAreas = resp.result || [];
        if (this.selectedZoneIds.length > 0) {
          zoneAreas = zoneAreas.filter((zone) =>
            this.selectedZoneIds.includes(zone.id)
          );
        }
        this.zones = zoneAreas.map((zone) => {
          // mapping coordinates format to remove the object point ({x: number, y: number}),
          // because the leaflet polygon accept array of tuple ([number, number])
          // and also color prop for refrence in the leaflet
          const coords = zone.coordinates[0];
          coords.pop();
          const data: ZoneArea = {
            ...zone,
            color: zone.metadata.color,
            coordinates: coords,
          };

          // render the zone in the leaflet map
          this.leafletService.renderZone(data, 'zone', {
            click: (e) => this.onZoneClicked(e),
          });

          return data;
        });
      } else {
        this.zones = [];
      }
    });
  }

  /**
   * When zone is clicked, select that zone,
   * it is used to update the selected zone,
   * so the dropdown in the create group patrol is updated
   *
   * @param e
   */
  private onZoneClicked(e: LeafletEvent): void {
    if (this.isReadOnlyMap) {
      return;
    }

    const zoneId = get(e, 'target.options.markerId', '') as string;
    const zone = this.zones.find((zone) => zone.id === zoneId);

    if (!zone) {
      return;
    }

    const selectionOption: SelectOption = {
      display: zone.name,
      value: JSON.stringify({
        markerId: zoneId,
        name: zone.name,
      }),
    };

    this.changeMarker.emit({ event: selectionOption, action: 'zone' });
  }

  /**
   * Helper function to load layout marker list from API based on selected layoutId, then render it to the map
   */
  private getMultipleRobotDetail(robots: Robot[]): void {
    const robotIds = robots.map((robot) => robot.id);
    const payload = {
      pageNo: 1,
      pageSize: robotIds.length,
      filter: [
        {
          name: 'ID',
          column: 'robots.id',
          operator: 'in',
          value: [...robotIds],
          extra: '',
          dataType: 'text',
          virtual: false,
        },
      ],
    };
    this.apiRobot.list(payload).subscribe((resp) => {
      if (resp.result) {
        this.robots = resp.result.list || [];
        if (!this.isUseRealRobotPosition) {
          this.leafletService.renderLayoutMarkers(
            this.robots,
            'robot',
            {
              opacity: 1,
              draggable: false,
            },
            this.isShowRobotSonar
          );
          this.isRobotMarkerRendered = true;
        }
      }
    });
  }

  private updateTeleopRobotPosition(data: RobotSocketData): void {
    // Update the robot marker position based on MQTT message re render the marker in the map
    if (data.robotId === this.robot.id && data.layout && this.isTeleopMap) {
      this.robot = {
        ...this.robot,
        point: {
          x: data.layout.x || 0,
          y: data.layout.y || 0,
          z: data.layout.z || 0,
        },
        heading: data.layout.heading || 0,
      };

      // Check if the robot is rendered, then it just update the position of the robot.
      // Otherwise it will render the robot first then move the map to centered the robot icon
      if (this.isRobotMarkerRendered) {
        this.leafletService.updateRobotMarkerPosition(
          'teleop-robot',
          this.robot
        );
      } else {
        this.leafletService.renderLayoutMarker(this.robot, 'teleop-robot', {
          opacity: 1,
          draggable: false,
        });
        const coordinates = this.leafletService.xYtoLatlng([
          this.robot.point.x,
          this.robot.point.y,
        ]);
        this.leafletService.flyTo(coordinates);
        this.isRobotMarkerRendered = true;
      }
    }
  }

  private updateRobotPosition(data: RobotSocketData): void {
    if (data && data.layout && data.robotId) {
      const robotIndex = this.robots.findIndex(
        (robot) => robot.id === data.robotId
      );

      // Update the robot marker position based on MQTT message re render the marker in the map
      if (robotIndex > -1 && data.layout) {
        this.robots[robotIndex] = {
          ...this.robots[robotIndex],
          malfunctionNumber: +this.robots[robotIndex].state === 5 ? 1 : 0,
          point: {
            x: data.layout.x || 0,
            y: data.layout.y || 0,
            z: data.layout.z || 0,
          },
          heading: data.layout.heading || 0,
        };
        if (!this.onLineRobotIdList.includes(data.robotId)) {
          this.onLineRobotIdList.push(data.robotId);
          this.leafletService.renderLayoutMarker(
            this.robots[robotIndex],
            'robot',
            {
              opacity: 1,
              draggable: false,
            },
            this.isShowRobotSonar
          );
        }

        this.leafletService.updateRobotMarkerPosition(
          'robot',
          this.robots[robotIndex]
        );

        // Update the robot status
        this.updateRobotStatusBySocket(data);
      }
    }
  }

  /**
   * Helper function to update robot status based on MQTT message
   *
   * @param socketData Robot data form MQTT socket
   */
  private updateRobotStatusBySocket(socketData: RobotSocketData): void {
    this.robots.map((robot) => {
      //  Check if the updated robot status is on the list
      if (robot.id === socketData.robotId) {
        robot.status = 1;
        // check the length of the timestamp, because the moment library
        // has two functions to convert timestamp into date format
        // and some of the robot, send status using this two formats.
        // the length can be 10 or 13
        if (socketData.timestamp.toString().length > 10) {
          robot.lastOnlineTime = moment(socketData.timestamp).fromNow();
        } else {
          robot.lastOnlineTime = moment.unix(socketData.timestamp).fromNow();
        }
        robot.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    });
  }

  /**
   * Helper function to check the robot status from MQTT for every 3 seconds.
   * It is used in robot list in the layout marker popup
   *
   */
  private checkRobotOfflineTimer(): void {
    this.checkOfflineTimer && clearInterval(this.checkOfflineTimer);
    this.checkOfflineTimer = setInterval(() => {
      if (this.onLineRobotIdList.length > 0) {
        this.robots.map((robot) => {
          if (this.onLineRobotIdList.includes(robot.id)) {
            const currentTime = moment();
            const updatedTime = moment(robot.updatedAt);
            const differenceInSeconds = currentTime.diff(
              updatedTime,
              'seconds'
            );
            if (robot.updatedAt && differenceInSeconds > 10) {
              console.log('robotRemove', robot);
              this.leafletService.removeMarker(robot.id, 'robot');
              robot.status = 2;
              const temp = this.onLineRobotIdList.filter(
                (id) => id !== robot.id
              );
              robot.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
              this.onLineRobotIdList = _.cloneDeep(temp);
            }
          }
        });
      }
    }, 3 * 1000);
  }

  /**
   * Helper function to update robot status based on MQTT message
   *
   * @param socketData Robot data form MQTT socket
   */
  private updateRobotStateBySocket(socketData: RobotSocketData): void {
    this.robots.map((robot) => {
      //  Check if the updated robot status is on the list
      if (robot.id === socketData.robotId) {
        const robotIndex = this.robots.findIndex(
          (robot) => robot.id === socketData.robotId
        );

        // update robot data and re render the marker
        // to update the malfunction number for selected robot
        if (robotIndex > -1 && socketData.state) {
          this.robots[robotIndex] = {
            ...this.robots[robotIndex],
            state: socketData.state,
            malfunctionNumber: +this.robots[robotIndex].state === 5 ? 1 : 0,
          };

          if (this.onLineRobotIdList.includes(socketData.robotId)) {
            this.leafletService.updateMarkerHtml(
              this.robots[robotIndex],
              this.isShowRobotSonar
            );
          }
        }
      }
    });
  }

  /**
   * When marker has finished being dragged, update its position
   * with the new coordinate. So the dropdown in the dispatch robot is updated
   *
   * @param e
   */
  private onNodeMapDragEnd = (e: LeafletEvent) => {
    // eslint-disable-next-line
    const coordinates = this.leafletService.latlngToXy(e.target.getLatLng());

    // Check if the marker placed outside layout preview image
    // eslint-disable-next-line
    const isValid = this.leafletService.isValidCoordinate(e.target.getLatLng());
    if (!isValid) {
      const marker = {
        id: this.dispatchRobotLocationFromMap.id,
        x: this.dispatchRobotLocationFromMap.x,
        y: this.dispatchRobotLocationFromMap.y,
        z: 0,
      };
      this.leafletService.updateMarkerLatLng(marker, 'dispatch-marker');
      this.snackbar.openSnackBar({
        message: 'Cannot place marker outside of the layout preview',
        type: 'failed',
      });
      return;
    }

    // update the marker with the new position
    this.dispatchRobotLocationFromMap = {
      ...this.dispatchRobotLocationFromMap,
      x: coordinates.x,
      y: coordinates.y,
    };

    const selectionOption: SelectOption = {
      display: this.dispatchRobotLocationFromMap.name,
      value: JSON.stringify({
        layoutId: this.layoutId,
        name: '',
        x: this.dispatchRobotLocationFromMap.x,
        y: this.dispatchRobotLocationFromMap.y,
        z: 0,
      }),
    };
    this.changeMarker.emit({ event: selectionOption, action: 'map' });
  };
}
